<template>
    <div id="aboutUs">
        <div class="Banan">
            <div class="contain">
                <h1>新芽教育</h1>
                <p>
                    广州新芽教育科技有限公司是一家数字化知识内容服务商，公司拥有一支具有十余年知识内容研发、策划运营经验的创始人团队，专注于在线知识付费领域，用优质的教育数字化内容服务于家庭教育以及智慧终端等行业，为用户及合作伙伴提供专业优质的知识内容整体解决方案。
                </p>
                <div class="module">
                    <h3>打造互联网教育运营创新模式</h3>
                    <img src="../../assets/jiantou.png" alt="">
                    <h3>内容的多种渠道变现模式</h3>
                </div>
                <p>
                    以内容为核心，掌握和创新内容的各种应用技术，实现产品标准化和品牌化，构建多屏互动的全场景内容消费生态圈，实现2B和2C双轮驱动的多种变现模式。
                </p>
                <div class="company" data-aos="fade-up" data-aos-once='true'>
                    <div class="companyItem">
                        <div class="itemTop">
                            <div class="itemTop_l">
                                <h5>企业使命</h5>
                                <p>Our Mission</p>
                            </div>
                            <div class="itemTop_r">
                                <img src="../../assets/icon_11.png" alt="">
                            </div>
                        </div>
                        <div class="itemBottom">
                            促进教育公平 共享优质教育
                        </div>
                    </div>
                    <div class="companyItem">
                        <div class="itemTop">
                            <div class="itemTop_l">
                                <h5>企业愿景</h5>
                                <p>Corporate Vision</p>
                            </div>
                            <div class="itemTop_r">
                                <img src="../../assets/icon_22.png" alt="">
                            </div>
                        </div>
                        <div class="itemBottom">
                            数字化知识内容的首选品牌
                        </div>
                    </div>
                    <div class="companyItem">
                        <div class="itemTop">
                            <div class="itemTop_l">
                                <h5>核心价值观</h5>
                                <p>Core Value</p>
                            </div>
                            <div class="itemTop_r">
                                <img src="../../assets/icon_33.png" alt="">
                            </div>
                        </div>
                        <div class="itemBottom">
                            专注 专业 创新
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 知识 -->
        <div class="knowledge" data-aos="fade-up" data-aos-once='true'>
            <h1>知识内容制作</h1>
            <div class="knowledegList">
                <div class="knowledegItem" data-aos="fade-up" data-aos-once='true'>
                    <div class="knowledegItem_l" style="margin:0 82px 0 57px">
                        <img src="../../assets/about1.png" alt="">
                    </div>
                    <div class="knowledegItem_r">
                        <h5 style="padding-top:88px">自主生产高清录播课+动漫内容多渠道</h5>
                        <p>自营策划团队</p>
                        <p>外聘优质师资</p>
                        <p>挖掘长期合作名师</p>
                    </div>
                </div>
                <div class="knowledegItem" data-aos="fade-up" data-aos-once='true'>
                    <div class="knowledegItem_r">
                        <h5 style="padding-top:97px"> 承接内容制作项目</h5>
                        <p>提供内容制作技术和后期制作服务</p>
                    </div>
                    <div class="knowledegItem_l" style="margin-left:52px">
                        <img src="../../assets/about2.png" alt="">
                    </div>
                </div>
                <div class="knowledegItem" data-aos="fade-up" data-aos-once='true'>
                    <div class="knowledegItem_l" style="margin:0 50px 0 73px">
                        <img src="../../assets/about3.png" alt="">
                    </div>
                    <div class="knowledegItem_r">
                        <h5 style="padding-top:105px">合作制作教育内容、版权共有</h5>
                        <p>依托双方各自优势，产出教育内容</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 师资 -->
        <div class="teachers" data-aos="fade-up" data-aos-once='true'>
            <h1>强大的师资实力</h1>
            <p>课程讲师来自全国24所重点学校及知名线下机构，有着丰富的一线教学经验；同时，我司与华南师范大学开展深度合作，依靠华师教研组丰富的教研经验，打造更优质的课件内容，呈现更优质的教育课程。</p>
            <img src="../../assets/pic_shizi.png" alt="">
        </div>
        <!-- 合作伙伴 -->
        <div class="cooperation" data-aos="fade-up" data-aos-once='true'>
            <h1>主要合作伙伴</h1>
            <div class="cooperationImg">
                <img src="../../assets/hezuohuoban.png" alt="">
            </div>
        </div>
        <!-- 联系我们 -->
        <div class="contact" data-aos="fade-up" data-aos-once='true'>
            <div class="contactContain">
                <h1>联系我们 CONTACT US</h1>
                <div class="contactList">
                    <div class="contactItem">
                        <img src="../../assets/contact1.png" alt="">
                        <label>TEL</label>
                        <p>020-84509397</p>
                    </div>
                    <div class="contactItem">
                        <img src="../../assets/contact2.png" alt="">
                        <label>E-MAIL</label>
                        <p>sprout_edu@163.com</p>
                    </div>
                    <div class="contactItem">
                        <img src="../../assets/contact3.png" alt="">
                        <label>ADD</label>
                        <p>广东省广州市番山创业中心3号楼2区6B层601-603室(新芽教育)</p>
                    </div>
                </div>
                <div id="addressMap">
                
                </div>
            </div>
            

        </div>
    </div>
</template>
<script>
export default {
    name:"aboutUs",
    data(){
        return{

        }
    },
    mounted() {
        this.baiduMap()
    },
    methods:{
        baiduMap() {
            this.map = new BMap.Map("addressMap");    
            var point = new BMap.Point(113.371332,22.985796);
            this.map.centerAndZoom(point, 19);
            // 初始化地图， 设置中心点坐标和地图级别
            var marker = new BMap.Marker(point);
            this.map.addOverlay(marker);
            
            var labelgg = new BMap.Label("广东省广州市番山创业中心3号楼2区6B层601-603室(新芽教育)",{offset:new BMap.Size(-170,-30)});
            marker.setLabel(labelgg); //添加GPS label

            
            //地图平移缩放控件
            this.map.addControl(new BMap.NavigationControl());
            //比例尺控件
            this.map.addControl(new BMap.ScaleControl());    
            //缩略地图控件
            this.map.addControl(new BMap.OverviewMapControl());   
            //地图类型控件
            this.map.addControl(new BMap.MapTypeControl());    
            this.map.setCurrentCity("广州"); // 仅当设置城市信息时，
        }
    }
}
</script>
<style lang="scss" spcoed>
#aboutUs{
    width:100%;
    background: #FFFFFF;
    .Banan{
        width:100%;
        height: 857px;
        background: url('../../assets/aboutUsBg.png') no-repeat bottom;
        background-size: 100% auto;
        .contain{
            width:1200px;
            margin: 0 auto;
            text-align: center;
            padding-top: 100px;
            h1{
                display: inline-block;
                position: relative;
                font-size: 38px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 48px;
            }
            h1::before{
                position: absolute;
                top: 50%;
                left: -90px;
                display: block;
                content: '';
                background: url('../../assets/titleLeft.png') no-repeat;
                width: 74px;
                height: 3px;
                transform: translateY(50%);
            }
            h1::after{
                position: absolute;
                top: 50%;
                right: -90px;
                display: block;
                content: '';
                background: url('../../assets/titleRight.png') no-repeat;
                width: 74px;
                height: 3px;
                transform: translateY(50%);
            }
            p{
                font-size: 24px;
                font-weight: 400;
                line-height: 36px;
                color: #000000;
                text-align: left;
                text-indent: 2em;
            }
            .module{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 44px 0 46px;
                h3{
                    font-size: 28px;
                    font-weight: bold;
                    line-height: 36px;
                    color: #000000;
                }
                img{
                    width:54px;
                    height:30px;
                    padding: 0 20px;
                }
            }
            .company{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 106px;
                &Item{  
                    width: 360px;
                    height: 155px;
                    background: #FFFFFF;
                    box-shadow: 0px 3px 6px rgba(3, 50, 91, 0.12);
                    border-radius: 12px;
                    .itemTop{
                        width:100%;
                        display: flex;
                        justify-content: space-between;
                        padding: 0 20px 0 26px;
                        box-sizing: border-box;
                        .itemTop_l{
                            text-align: left;
                            h5{
                                font-size: 20px;
                                font-weight: bold;
                                line-height: 36px;
                                color: #000000;
                                padding-top: 18px;
                            }
                            p{
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 26px;
                                color: #000000;
                                text-indent: 0;
                            }
                        }
                        .itemTop_r{
                            width:100px;
                            height: 100%;
                            margin-top: -22px;
                            img{
                                width:100%;
                                height:100%;
                            }
                        }
                    }
                    .itemBottom{
                        margin-top: 16px;
                        width:100%;
                        border-top: 1px solid #D8E7F5;
                        padding: 9px 0 0 26px;
                        box-sizing: border-box;
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 36px;
                        color: #000000;
                        text-align: left;
                    }
                }
            }
        }
    }
    .knowledge{
        width: 1200px;
        margin: 0 auto;
        padding-top: 100px;
        text-align: center;
        h1{
            display: inline-block;
            position: relative;
            font-size: 38px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 48px;
        }
        h1::before{
            position: absolute;
            top: 50%;
            left: -90px;
            display: block;
            content: '';
            background: url('../../assets/titleLeft.png') no-repeat;
            width: 74px;
            height: 3px;
            transform: translateY(50%);
        }
        h1::after{
            position: absolute;
            top: 50%;
            right: -90px;
            display: block;
            content: '';
            background: url('../../assets/titleRight.png') no-repeat;
            width: 74px;
            height: 3px;
            transform: translateY(50%);
        }
        .knowledegList{
            margin-top:100px;
            width:100%;
            .knowledegItem{
                width: 100%;
                display: flex;
                margin-bottom: 110px;
                &_l{
                    width: 461.48px;
                    height: 320.58px;
                    img{
                        width:100%;
                        height: 100%;
                    }
                }
                &_r{
                    width:600px;
                    text-align: center;
                    h5{
                        font-size: 32px;
                        font-weight: bold;
                        line-height: 54px;
                        color: #000000;
                        margin: 0 0 33px;
                    }
                    p{
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 36px;
                        color: #686868;
                    }
                }
            }
        }
    }
    .teachers{
        width: 1200px;
        margin: 0 auto;
        padding-top: 81px;
        text-align: center;
        h1{
            display: inline-block;
            position: relative;
            font-size: 38px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 80px;
        }
        h1::before{
            position: absolute;
            top: 50%;
            left: -90px;
            display: block;
            content: '';
            background: url('../../assets/titleLeft.png') no-repeat;
            width: 74px;
            height: 3px;
            transform: translateY(50%);
        }
        h1::after{
            position: absolute;
            top: 50%;
            right: -90px;
            display: block;
            content: '';
            background: url('../../assets/titleRight.png') no-repeat;
            width: 74px;
            height: 3px;
            transform: translateY(50%);
        }
        p{
            font-size: 28px;
            font-weight: 400;
            line-height: 42px;
            color: #000000;
            text-align: left;
        }
        img{
            margin-top: 83px;
            display: block;
            width: 100%;
            height: 325px;
        }
    }
    .cooperation{
        width: 1200px;
        margin: 0 auto;
        padding: 278px 0 242px;
        text-align: center;
        position: relative;
        h1{
            display: inline-block;
            position: relative;
            font-size: 38px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 80px;
        }
        h1::before{
            position: absolute;
            top: 50%;
            left: -90px;
            display: block;
            content: '';
            background: url('../../assets/titleLeft.png') no-repeat;
            width: 74px;
            height: 3px;
            transform: translateY(50%);
        }
        h1::after{
            position: absolute;
            top: 50%;
            right: -90px;
            display: block;
            content: '';
            background: url('../../assets/titleRight.png') no-repeat;
            width: 74px;
            height: 3px;
            transform: translateY(50%);
        }
        .cooperationImg{
            position: absolute;
            bottom: 144px;
            left: 0;
            height: 98px;
            overflow: hidden;
            img{
                height: 100%;
                animation: overflowX 20s linear infinite alternate;
            }
        }
    }
    .contact{
        width:100%;
        height: 1049px;
        background-color: #F4F7F9;
        &Contain{
            width:956px;
            margin: 0 auto;
            text-align: center;
            padding: 100px;
            h1{
                display: inline-block;
                position: relative;
                font-size: 38px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 110px;
            }
            h1::before{
                position: absolute;
                top: 50%;
                left: -90px;
                display: block;
                content: '';
                background: url('../../assets/titleLeft.png') no-repeat;
                width: 74px;
                height: 3px;
                transform: translateY(50%);
                animation: rotaes 1s linear infinite alternate;
            }
            h1::after{
                position: absolute;
                top: 50%;
                right: -90px;
                display: block;
                content: '';
                background: url('../../assets/titleRight.png') no-repeat;
                width: 74px;
                height: 3px;
                transform: translateY(50%);
                animation: rotaes 1s linear infinite alternate;
            }
            .contactList{
                width: 100%;
                padding:0 33px;
                box-sizing: border-box;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .contactItem{
                    width: 198px;
                    img{
                        width: 132px;
                        height: 132px;
                        display: block;
                        margin:0 auto;
                    }
                    label{
                        display: block;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 31px;
                        color: #70757D;
                        text-align: center;
                        margin: 8px 0 12px;
                    }
                    p{
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #000000;
                    }
                }
            }
            #addressMap{
                width: 100%;
                height: 354px;
                background: #C1C1C1;
                border-radius: 20px;
                margin-top: 80px;
            }
        }
        
    }
}
@keyframes overflowX{
    0%{transform: translateX(0%);}
    100%{transform: translateX(-80%);}
}
@keyframes rotaes{
    0%{transform:rotateY(0)}
    100%{transform:rotateY(180deg)}
}
</style>